
export default {
  data () {
    return {
      // 下面数据明细-表头第一层
      tableTopColumns: [
        BaseTableColumn.initData('', '省/市/区/小区').setH5ColSpan(5),
        BaseTableColumn.initData('', '设备/雨棚/位置-数量').setH5ColSpan(5),
        BaseTableColumn.initData('', '端口').setH5ColSpan(2),
        BaseTableColumn.initData('', '有效充电订单').setH5ColSpan(2),
        BaseTableColumn.initData('', '用户').setH5ColSpan(3),
        BaseTableColumn.initData('', '人工退款单').setH5ColSpan(2)
      ],
      // 下面数据明细-表头第二层
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('provinceName', '省份', 40),
        BaseTableColumn.initData('cityName', '城市', 40),
        BaseTableColumn.initData('districtName', '区域', 40),
        BaseTableColumn.initData('communityName', '小区名称', 60),
        BaseTableColumn.initData('enabledDeviceNum', '充电桩总数', 40),
        BaseTableColumn.initData('groundCanopyDeviceNum', '地面已装', 60),
        BaseTableColumn.initData('groundNotCanopyDeviceNum', '地面未装', 30),
        BaseTableColumn.initData('undergroundDeviceNum', '地下', 40),
        BaseTableColumn.initData('elevatedDeviceNum', '架空层', 30),
        BaseTableColumn.initData('portUseRate', '使用率', 30).setSpan({ canTextUnit: 'p' }),
        BaseTableColumn.initData('enabledPortNum', '总数', 30),
        BaseTableColumn.initData('availableOrderNum', '总单数', 40),
        BaseTableColumn.initData('availableOrderAmount', '总消费金额', 40),
        BaseTableColumn.initData('customerNum', '总数量', 30),
        BaseTableColumn.initData('customerChargeAvgNum', '平均充电次数', 30),
        BaseTableColumn.initData('customerAvgAmount', '客单价', 30),
        BaseTableColumn.initData('manualRefundOrderNum', '总单数', 40),
        BaseTableColumn.initData('manualRefundOrderAmount', '总金额', 40)
      ]
    };
  }
};
